import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const CTASection = styled.section`
  margin: ${({ theme }) => theme.spacings.x10} 0;
  border-radius: ${({ theme }) => theme.spacings.x6};
  background-color: ${({ theme }) => theme.colors.primary.first};
  color: ${({ theme }) => theme.colors.text.dark};
  position: relative;
  padding: ${({ theme }) => theme.spacings.x7} 0;
  overflow: hidden;

  ${breakpoints.MD} {
    border-radius: 100px;
    padding: ${({ theme }) => parseInt(theme.spacings.x8) + 3}px 0
      ${({ theme }) => parseInt(theme.spacings.x8) - 11}px;
  }

  ${breakpoints.MDL} {
    padding: ${({ theme }) => parseInt(theme.spacings.x20) + 3}px 0
      ${({ theme }) => parseInt(theme.spacings.x7)}px;
  }

  ${breakpoints.LG} {
    padding: ${({ theme }) => parseInt(theme.spacings.x20) + 3}px 0
      ${({ theme }) => parseInt(theme.spacings.x7)}px;
  }
`;

const CTATitle = styled.h2`
  position: absolute;
  left: -4px;
  top: ${({ theme }) => theme.spacings.x7};
  font-size: 146px;
  line-height: 1;
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  align-items: flex-start;
  align-content: flex-start;
  will-change: transform;

  ${breakpoints.MD} {
    font-size: 128px;
    top: ${({ theme }) => theme.spacings.x10};
  }

  ${breakpoints.MDL} {
    font-size: 172px;
    top: 163px;
  }

  ${breakpoints.LG} {
    top: 159px;
    font-size: 240px;
  }

  span {
    position: relative;
    top: 4px;
  }
`;

const CTAGrid = styled.div`
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  grid-template-areas:
    '. action'
    '. description';
  row-gap: ${({ theme }) => theme.spacings.x3};
  column-gap: 18px;

  ${breakpoints.MD} {
    grid-template-columns: repeat(12, 12fr);
  }

  ${breakpoints.MDL} {
    row-gap: 12px;
  }

  ${breakpoints.LG} {
    row-gap: 14px;
  }
`;

const CTAAction = styled.div`
  grid-area: action;
  grid-column-start: 3;
  grid-column-end: 5;

  ${breakpoints.MD} {
    grid-column-start: 8;
    grid-column-end: 12;
  }

  ${breakpoints.MDL} {
    grid-column-start: 8;
    grid-column-end: 12;
  }

  ${breakpoints.LG} {
    grid-column-end: 10;
  }

  .action-wrapper {
    margin: 0 auto;
    width: 160px;

    ${breakpoints.MD} {
      margin-left: auto;
      margin-right: ${({ theme }) => theme.spacings.x5};
    }

    ${breakpoints.MDL} {
      padding-bottom: 30px;
      margin-right: 30%;
    }

    ${breakpoints.LG} {
      width: 180px;
      padding-bottom: 20px;
      padding-top: 23px;
      margin-right: -26px;
    }
  }

  a,
  button {
    margin: 0 auto;
  }
`;

const CTADescription = styled.p`
  grid-area: description;
  grid-column-start: 2;
  grid-column-end: 5;

  ${breakpoints.MD} {
    grid-column-start: 9;
    grid-column-end: 13;
    padding: 0 4%;
  }

  ${breakpoints.MDL} {
    grid-column-start: 10;
    grid-column-end: 13;
  }

  ${breakpoints.LG} {
    grid-column-start: 10;
    grid-column-end: 13;

    br {
      display: none;
      padding-right: 0;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const CTAGraphics = styled.div`
  position: absolute;
  left: 0;
  bottom: -115px;
  pointer-events: none;

  svg {
    width: 174px;
    height: 226px;
  }

  ${breakpoints.MD} {
    left: 21%;
    bottom: -143px;

    svg {
      width: 403px;
      height: 369px;
      transform: rotate(-15deg);
    }
  }

  ${breakpoints.MDL} {
    left: 30%;
    bottom: -133px;

    svg {
      width: 427px;
      height: 390px;
    }
  }

  ${breakpoints.LG} {
    left: 33%;
    bottom: -143px;

    svg {
      width: 502px;
      height: 440px;
    }
  }
`;

export {
  CTASection,
  CTAGrid,
  CTATitle,
  CTAAction,
  CTADescription,
  CTAGraphics,
  Wrapper,
};
