import { ReactElement, useEffect, useRef, useState } from 'react';
import {
  CTAAction,
  CTADescription,
  CTAGraphics,
  CTAGrid,
  CTASection,
  CTATitle,
  Wrapper,
} from '@/scenes/common/CTA/styles';
import Button from '@/components/UI/Button';
import { gsap, Power0, Power4 } from 'gsap';
import { SplitText } from '@/utils/gsap/SplitText';
import { useRouter } from 'next/router';
import uniq from 'lodash.uniq';

interface CTAProps {
  title?: string;
  description?: ReactElement;
  action?: ReactElement;
  graphics?: ReactElement;
}

const CTA = ({ title, description, action, graphics }: CTAProps) => {
  const router = useRouter();
  const tickerRef1 = useRef(null);
  const tickerTextRef = useRef(null);

  const [loaded, setLoaded] = useState(false);

  const animatedTextItems = useRef([]);
  const fadeItemsRef = useRef([]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;

    const delay = router.pathname === '/' ? 1500 : 0;

    setTimeout(() => {
      uniq(fadeItemsRef.current.filter((i) => i)).forEach((element) => {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: 'top 92%',
          },
        });
        tl.fromTo(
          element,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.75,
            delay: 0.2,
            ease: Power4.easeInOut,
          },
        );
      });

      uniq(animatedTextItems.current.filter((i) => i)).forEach((element) => {
        const childSplit = new SplitText(element, {
          type: 'lines',
          linesClass: 'animatedLineChild',
        });
        const parentSplit = new SplitText(element, {
          type: 'lines',
          linesClass: 'animatedLineParent',
        });

        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: 'top 92%',
          },
        });
        tl.fromTo(
          childSplit.lines,
          {
            transition: 'none',
            transformOrigin: '0% 0%',
            scaleY: 1,
            yPercent: 100,
          },
          {
            duration: 0.75,
            transformOrigin: '0% 0%',
            delay: 0.2,
            scaleY: 1,
            opacity: 1,
            y: 0,
            yPercent: 0,
            stagger: 0.075,
            ease: Power4.easeInOut,
            onComplete: () => {
              // @ts-ignore
              childSplit.revert();
            },
          },
        ).set(parentSplit.lines, { overflow: 'visible' }, 0.2);
      });
    }, delay);

    const textWidth = tickerTextRef.current.offsetWidth;

    const tl = gsap.timeline({ repeat: -1 });

    tl.to(tickerRef1.current, {
      duration: 5,
      x: `-=${textWidth}`,
      ease: Power0.easeNone,
      onComplete: () => {
        const style = window.getComputedStyle(tickerRef1.current);
        const matrix = new WebKitCSSMatrix(style.webkitTransform);

        if (matrix.m41 <= -textWidth) {
          gsap.set(tickerRef1.current, {
            x: 0,
          });
        }

        tl.invalidate();
      },
    });

    return () => {
      tl.kill();
    };
  }, [loaded]);

  return (
    <Wrapper>
      <CTASection>
        <CTATitle
          ref={(r) => {
            tickerRef1.current = r;
            fadeItemsRef.current.push(r);
          }}
        >
          <span ref={tickerTextRef}>{title ? title : 'Buy a bear'}&nbsp;</span>
          {loaded && <span>{title ? title : 'Buy a bear'}&nbsp;</span>}
          {loaded && <span>{title ? title : 'Buy a bear'}&nbsp;</span>}
          {loaded && <span>{title ? title : 'Buy a bear'}&nbsp;</span>}
          {loaded && <span>{title ? title : 'Buy a bear'}&nbsp;</span>}
        </CTATitle>
        <div className="container">
          <CTAGrid>
            <CTAAction>
              <div
                className="action-wrapper"
                ref={(r) => fadeItemsRef.current.push(r)}
              >
                {action ? (
                  action
                ) : (
                  <>
                    <Button
                      as="a"
                      href="https://linktr.ee/srb_nft"
                      target="_blank"
                      type="primary"
                    >
                      Buy a bear
                    </Button>
                  </>
                )}
              </div>
            </CTAAction>
            <CTADescription ref={(r) => animatedTextItems.current.push(r)}>
              {description ? (
                description
              ) : (
                <>
                  Bear with us.&nbsp;
                  <br />
                  Own the NFTs, use them as you wish, and get a membership to
                  the Elite Super Rare Club.
                </>
              )}
            </CTADescription>
          </CTAGrid>
        </div>
      </CTASection>
      {graphics && (
        <CTAGraphics ref={(r) => fadeItemsRef.current.push(r)}>
          {graphics}
        </CTAGraphics>
      )}
    </Wrapper>
  );
};

export default CTA;
